import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import { SiteBackLink, LinkBody } from './styles';

const BackLink = ({ body, theme = 'primary', className, handleOnClick }) => {
  return (
    <SiteBackLink
      role="back"
      title="back"
      aria-label="back"
      onClick={handleOnClick}
      className={`${theme} ${className ? className : ''}`}
    >
      <Icon type="left-arrow-ellipse" />
      {body && <LinkBody>{body}</LinkBody>}
    </SiteBackLink>
  );
};

BackLink.propTypes = {
  body: PropTypes.string,
  theme: PropTypes.string,
  handleOnClick: PropTypes.func,
  className: PropTypes.string,
};

export default BackLink;
