import styled from 'styled-components';

export const StyleguideContainer = styled.div`
  > div {
    margin-bottom: 40px;
  }

  .typography,
  .inputs-and-forms {
    > * {
      margin-bottom: 20px;
    }
  }
`;
