import styled, { css } from 'styled-components';

export const siteBackLinkStyles = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.primary {
    color: ${(props) => props.theme.colors.white};

    a {
      color: ${(props) => props.theme.colors.white};
    }

    .Icon {
      path,
      circle {
        stroke: ${(props) => props.theme.colors.white};
      }
    }
  }

  &.secondary {
    color: ${(props) => props.theme.colors.blue};

    a {
      color: ${(props) => props.theme.colors.blue};
    }

    .Icon {
      path,
      circle {
        stroke: ${(props) => props.theme.colors.blue};
      }
    }
  }

  .Icon {
    width: 36px;
    height: 36px;
  }
`;

export const SiteBackLink = styled.button`
  ${siteBackLinkStyles};
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;

  .Icon {
    margin-right: 15px;
    flex: none;
  }
`;

export const LinkBody = styled.span``;
